import React from 'react';

import performanceIcon from '@images/golang-development/why-use-golang/performance.svg';
import memorySafeIcon from '@images/rust-development/why-use-rust/memory-safety.svg';
import programmingIcon from '@images/rust-development/why-use-rust/programming.svg';
import crossPlatformIcon from '@images/rust-development/why-use-rust/cross-platform.svg';

export const whyUseRustData = {
  sectionTitle: 'Why use the Rust programming language for your project?',
  sectionId: 'why-use-rust',
  firstParagraph: (
    <>
      Rust is the most beloved programming language among developers, according to the Stack Overflow survey. It is
      often compared with the C/C++ programming language regarding the level of control over what a computer is doing
      for you while <span className="bold-font">maintaining a low performance overhead.</span>
    </>
  ),
  secondParagraph: (
    <>
      As a multi-paradigm, high-level, general-purpose programming language, Rust shares similar syntax with C and C++
      but offers a higher level of memory safety without using a garbage collector.{' '}
      <span className="bold-font">
        That is why (almost) anything you can do with C/C++ you can do with Rust, but better!
      </span>
    </>
  ),
  thirdParagraph: 'What are the other benefits of using Rust?',
  methods: [
    {
      src: performanceIcon,
      title: 'Performance',
      subtitle: 'Rust is memory-efficient, fast, and can be an excellent choice for performance-critical services.',
    },
    {
      src: memorySafeIcon,
      title: 'Memory Safety',
      subtitle: (
        <>
          You don&apos;t need to choose between memory safety and speed. Rust enforces safe memory management and
          prevents bugs and security vulnerabilities when your software is already in production.
        </>
      ),
    },
    {
      src: programmingIcon,
      title: 'Concurrency/Parallel Programming',
      subtitle:
        'Rust prevents concurrency-related issues such as data races. It has built-in support for multi-threading, resources borrowing and ownership rules that allow writing very safe concurrent code.',
    },
    {
      src: crossPlatformIcon,
      title: 'Cross-Platform Capabilities',
      subtitle: 'Rust provides cross-platform support, which helps companies to migrate and deploy applications.',
    },
  ],
};
