import React from 'react';

export const paragraphsData = {
  firstParagraph: (
    <>
      Build outstanding software solutions for networks with Rust - a language that takes C and C++ to the next level.
      As a systems programming language that handles concurrency and ensures memory safety, Rust can be a{' '}
      <span className="bold-text">perfect match for your cutting-edge software projects</span> where reliability and
      performance are crucial.
    </>
  ),
  secondParagraph: (
    <>
      CodiLime&apos;s <span className="bold-text">Rust developer teams</span> are ready to give you a hand with your
      software development, as we have been doing since 2011 for tech-driven businesses all over the globe.
    </>
  ),
};
