import React from 'react';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { SingleBoxDescriptive } from '@commons/single-box-descriptive/SingleBoxDescriptive';

import { weHelpYouData } from './payload';

import * as styles from './we-help-you.module.scss';

export const WeHelpYou = () => {
  const { boxesData } = weHelpYouData;
  return (
    <StandardSection
      title="Our Rust developers will help you with:"
      id="we-help-you"
      className={styles.section}
      classNames={{ innerWrapper: styles.innerWrapper, title: styles.mainTitle }}
    >
      <div className={styles.boxesContainer}>
        {boxesData.map((box, i) => (
          <SingleBoxDescriptive key={i} {...box} classNames={{ description: styles.singleBoxDescription }} />
        ))}
      </div>
    </StandardSection>
  );
};
