export const rustArticlesData = {
  paragraph:
    'There is so much to say about the benefits of using the Rust programming language, and there is just not enough space here to cover it all. Happily, we have so much more for you to read about Rust. Check out our articles and other content related to Rust development.',
  buttonsData: [
    {
      id: '1',
      title: 'Why is Rust programming language so popular?',
      href: 'https://codilime.com/blog/why-is-rust-programming-language-so-popular/',
    },
    {
      id: '2',
      title: 'Rust vs C: safety and performance in low-level network programming',
      href: 'https://codilime.com/blog/rust-vs-c-safety-and-performance-in-low-level-network-programming/',
    },
    {
      id: '3',
      title: 'Rust vs. C++—the main differences between these popular programming languages',
      href: 'https://codilime.com/blog/rust-vs-cpp-the-main-differences-between-these-popular-programming-languages/',
    },
    {
      id: '4',
      title: 'Building a web application for network monitoring in heterogeneous environments',
      href: 'https://codilime.com/case-study/building-web-application-for-network-monitoring-in-heterogeneous-environments/',
    },
  ],
};
