import React from 'react';

import Layout from '@src/layouts';
import SEO from '@commons/SEO';
import { StandardSection } from '@commons/standard-section/StandardSection';
import { Header } from '@commons/header/Header';
import { MvpTestimonials } from '@pages-impl/mvp-for-startups';
import { AboutUs, WhyUseRust, WeHelpYou, OurApproach, RustArticles } from '@pages-impl/rust-development';
import { LetsTalkSection } from '@pages-impl/shared/lets-talk-section/LetsTalkSection';
import featuredImage from '@images/header-images/rust-development-services.jpg';
import downloadImage from '@images/rust-development/about-us.webp';

import { letsTalkData } from '@pages-impl/rust-development/payload.js';

import * as styles from './rust-development.module.scss';

export default function Rust(props) {
  const headerTitle = 'Rust development services';

  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Fire up your Rust project with us"
      oldPage
      {...props}
    >
      <SEO
        title="Rust Development Services Company - CodiLime | Strategic Partner"
        description=" Our Rust development teams are here to provide you with top-notch software development services. Build in Rust with an experienced strategic partner."
        featuredImage={featuredImage}
      />
      <Header
        title={headerTitle}
        showImageOnMobile
        src="rust_development_header.jpg"
        srcSmall="codilime_golang_header_small.png"
        className={styles.header}
      />
      <AboutUs />
      <StandardSection
        className={styles.downloadImage}
        classNames={{ outerWrapper: styles.outerWrapper, innerWrapper: styles.innerWrapper }}
      >
        <img loading="lazy" src={downloadImage} alt="Get in touch" />
      </StandardSection>
      <WhyUseRust />
      <LetsTalkSection {...letsTalkData} />
      <WeHelpYou />
      <OurApproach />
      <MvpTestimonials
        title={<>Our clients&apos; testimonials</>}
        className={styles.section}
        classNames={{ title: styles.contactTitle }}
      />
      <RustArticles />
    </Layout>
  );
}
