import React from 'react';
import { CheckOutSection } from '@pages-impl/shared/check-out-section/CheckOutSection';

import downloadImage from '@images/rust-development/rust-articles/articlesImage.webp';
import { rustArticlesData } from './payload';

import * as styles from './rust-articles.module.scss';

export const RustArticles = () => {
  return (
    <CheckOutSection
      {...rustArticlesData}
      sectionTitle="Check out our Rust articles"
      sectionId="check-out-rust-articles"
      downloadImage={downloadImage}
      imageAltText="articles"
      classNames={{ imageContainer: styles.imageContainer, buttonStyles: styles.button }}
    />
  );
};
