import React from 'react';
import { OurApproachSection } from '@pages-impl/shared/our-approach/OurApproachSection';

import * as styles from './our-approach.module.scss';

export const OurApproach = () => {
  return (
    <OurApproachSection
      sectionTitle="Rust development—our approach"
      sectionId="rust-our-approach"
      classNames={{
        paragraphStyles: styles.paragraph,
        boxesContainerStyles: styles.boxesContainer,
        singleBoxDescriptionStyles: styles.singleBoxDescription,
      }}
    />
  );
};
