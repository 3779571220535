import React from 'react';
import cx from 'classnames';

import { StandardSection } from '@commons/standard-section/StandardSection';
import { ParagraphsWithBoxes } from '@pages-impl/golang-development/common/ParagraphsWithBoxes';

import * as styles from './why-use-technology.module.scss';

export const WhyUseTechnology = ({
  sectionTitle,
  sectionId,
  firstParagraph,
  secondParagraph,
  thirdParagraph = '',
  methods,
  className: { section, singleBoxDescription } = {},
}) => {
  return (
    <StandardSection
      title={sectionTitle}
      className={cx(styles.section, section)}
      classNames={{ innerWrapper: styles.innerWrapper, title: styles.mainTitle }}
      id={sectionId}
    >
      <ParagraphsWithBoxes
        firstParagraph={firstParagraph}
        secondParagraph={secondParagraph}
        thirdParagraph={thirdParagraph}
        methods={methods}
        classNames={{
          boxesContainer: styles.boxesContainer,
          singleBoxDescription: cx(styles.singleBoxDescription, singleBoxDescription),
        }}
      />
    </StandardSection>
  );
};
