import networkIcon from '@images/rust-development/we-help-you/network.svg';
import screenIcon from '@images/rust-development/we-help-you/screen.svg';
import webAppIcon from '@images/rust-development/we-help-you/web-app.svg';
import graphIcon from '@images/rust-development/we-help-you/graph.svg';
import embeddedSystemIcon from '@images/rust-development/we-help-you/embedded-system.svg';
import cloudBasedAppIcon from '@images/rust-development/we-help-you/cloud-based-app.svg';
import monitoringAppIcon from '@images/rust-development/we-help-you/monitoring-app.svg';

export const weHelpYouData = {
  boxesData: [
    {
      src: networkIcon,
      subtitle: 'Network solutions',
    },
    {
      src: screenIcon,
      subtitle: 'Low-level programming',
    },
    {
      src: webAppIcon,
      subtitle: 'Web applications programming with Rust',
    },
    {
      src: graphIcon,
      subtitle: 'Command line applications',
    },
    {
      src: embeddedSystemIcon,
      subtitle: 'Embedded systems programming',
    },
    {
      src: cloudBasedAppIcon,
      subtitle: 'Performance optimization for cloud-based apps',
    },
    {
      src: monitoringAppIcon,
      subtitle: 'Low overhead performance monitoring applications',
    },
  ],
};
