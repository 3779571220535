import React from 'react';

import { AboutUsSection } from '@pages-impl/shared/about-us-section/AboutUsSection';

import downloadImage from '@images/rust-development/about-us.webp';
import { paragraphsData } from './payload';

import * as styles from './about-us.module.scss';

export const AboutUs = () => {
  return (
    <AboutUsSection
      {...paragraphsData}
      buttonLabel="Let’s Rust together!"
      buttonHref="/services/rust-development/#contact"
      imageOnRight={downloadImage}
      imageAltText="Rust"
      noImgOnMobile
      spaceOnMobile
      classNames={{
        customImgStyles: styles.container,
        outerWrapper: styles.outerWrapper,
        sectionCustomStyles: styles.section,
      }}
    />
  );
};
